import { UserOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Space,
  Switch,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const MutEnquireHome = () => {
  const [form] = Form.useForm();
  const [onEdit, setOnEdit] = useState(false);
  const [enquires, setEnquires] = useState(null);
  const [fullData, setFullData] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [showArchived, setShowArchived] = useState(false);
  const [cevapModal, setCevapModal] = useState(false);
  const [selectedSoru, setSelectedSoru] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.myEnquires(JSON.parse(localStorage.getItem("user"))?.id)
        .then((response) => {
          setFullData(response.data);
          setEnquires(response.data.filter((item) => !item.isArchived));
          console.log("Enquires fetched successfully:", response.data);
        })
        .catch((error) => {
          console.error("Error fetching Enquires:", error);
        });
    }
  }, [initialRender]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      className: "text-center",
    },
    {
      title: "Mentor",
      dataIndex: "mentor",
      key: "mentor",
      sorter: (a, b) => a.mentor.localeCompare(b.mentor),
    },
    {
      title: "Soru",
      dataIndex: "soru",
      key: "soru",
      sorter: (a, b) => a.soru.localeCompare(b.soru),
    },

    {
      title: "Cevap Tarihi",
      dataIndex: "cevapTarihi",
      key: "cevapTarihi",
      render: (text) => (text ? moment(text).format("DD MMM YYYY HH:mm") : "-"),
      sorter: (a, b) => new Date(a.cevapTarihi) - new Date(b.cevapTarihi),
    },
    {
      title: "Oluşturulma",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (text) => moment(text).format("DD MMM YYYY HH:mm"),
      sorter: (a, b) => new Date(a.createdOn) - new Date(b.createdOn),
    },
    {
      title: "Kişi",
      dataIndex: "kisi",
      key: "kisi",
      sorter: (a, b) => a.kisi - b.kisi,
      className: "text-center",
    },

    {
      title: "Aktif mi?",
      dataIndex: "isActive",
      key: "isActive",
      render: (value) => (value ? "✔️" : "❌"),
      sorter: (a, b) => a.isActive - b.isActive,
      className: "text-center",
    },
    {
      title: "Arşivlendi mi?",
      dataIndex: "isArchived",
      key: "isArchived",
      render: (value) => (value ? "✔️" : "❌"),
      sorter: (a, b) => a.isArchived - b.isArchived,
      className: "text-center",
    },
    {
      title: "Işlemler",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {record.mentorId === JSON.parse(localStorage.getItem("user"))?.id && (
            <Button
              size="small"
              type="primary"
              onClick={() => {
                setOnEdit(true);
                form.setFieldsValue({
                  ...record,
                  mentor: JSON.parse(localStorage.getItem("user"))?.name,
                  cevapTarihi: record.cevapTarihi
                    ? moment(record.cevapTarihi)
                    : null,
                });
              }}
            >
              Düzenle
            </Button>
          )}
          {record.mentorId === JSON.parse(localStorage.getItem("user"))?.id && (
            <Popconfirm
              title="Silmek istediğinize emin misiniz?"
              onConfirm={() => {
                AdminService.deleteEnquire(record.id).then((response) => {
                  notification.success({
                    message: "Başarılı",
                    description: "Başarıyla silindi",
                  });
                  console.log("Enquire deleted successfully:", response.data);
                  setInitialRender(true);
                });
              }}
              onCancel={() => {
                notification.error({
                  message: "İşlem iptal edildi",
                  description: "Silme işlemi iptal edildi",
                });
              }}
              okText="Evet"
              cancelText="Hayır"
              placement="topRight"
              arrowPointAtCenter
            >
              <Button size="small" type="primary" danger>
                Sil
              </Button>
            </Popconfirm>
          )}
          <Button
            disabled={record.cevap === ""}
            size="small"
            type="primary"
            onClick={() => {
              setSelectedSoru(record);

              setCevapModal(true);
            }}
          >
            Cevap
          </Button>
        </Space>
      ),
    },
  ];
  const onFinish = (values) => {
    console.log(JSON.parse(localStorage.getItem("user"))?.id);
    values.mentorId = JSON.parse(localStorage.getItem("user"))?.id;
    console.log("Form submitted:", values);
    if (onEdit) {
      AdminService.updateEnquire(values).then((response) => {
        notification.success({
          message: "Başarılı",
          description: "Başarıyla güncellendi",
        });
        console.log("Form updated successfully:", response.data);
        setInitialRender(true);
        setOnEdit(false);
        form.resetFields();
      });
    } else {
      AdminService.newEnquire(values).then((response) => {
        notification.success({
          message: "Başarılı",
          description: "Başarıyla kaydedildi",
        });
        console.log("Form submitted successfully:", response.data);
        setInitialRender(true);
        form.resetFields();
      });
    }
  };
  const initialValues = {
    mentor: JSON.parse(localStorage.getItem("user"))?.name,
    soru: "",
    cevap: "",
    kisi: 0,
    isActive: false,
    isArchived: false,
    cevapTarihi: null,
    cevaplayan: "",
    isGlobal: false,
  };
  return (
    <div className="max-w-9xl mx-auto p-8">
      <Modal
        open={cevapModal}
        footer={
          <div className="flex text-xs justify-between">
            <p>
              <b>Cevaplayan: </b>
              {selectedSoru?.cevaplayan}
            </p>
            <p>
              <b>Tarih: </b>
              {moment(selectedSoru?.cevapTarihi).format("DD MMM YYYY HH:mm")}
            </p>
          </div>
        }
        onCancel={() => setCevapModal(false)}
      >
        <span>{selectedSoru?.cevap}</span>
      </Modal>
      <Divider className="text-2xl font-bold mb-4">Aktif Sorular</Divider>
      <Table
        title={() => (
          <div className="flex justify-between items-center">
            <span> Toplam {fullData?.length} soru </span>
            <Space>
              <span className="text-sm">Arşivlenmiş Soruları Göster</span>
              <Switch
                checked={showArchived}
                onChange={(checked) => {
                  setShowArchived(checked);
                  if (checked) {
                    setEnquires(fullData);
                  } else {
                    setEnquires(fullData.filter((item) => !item.isArchived));
                  }
                }}
              />
            </Space>
          </div>
        )}
        dataSource={enquires}
        columns={columns}
        rowKey="id"
        size="small"
        pagination={false}
        bordered
        loading={enquires === null}
      />
      <br />
      <br />
      <Divider className="text-2xl font-bold mb-4">Yeni soru oluştur</Divider>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        className="max-w-2xl"
        style={{ margin: "0 auto" }}
      >
        <Form.Item name="id" style={{ display: "none" }}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ display: "none" }}
          name="isGlobal"
          label="Is Global?"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="mentor" label="Mentor">
          <Input prefix={<UserOutlined />} placeholder="Mentor" />
        </Form.Item>

        <Form.Item
          name="soru"
          label="Soru"
          rules={[{ required: true, message: "Lütfen soruyu giriniz" }]}
        >
          <Input.TextArea rows={4} placeholder="Soru" />
        </Form.Item>

        <Form.Item
          name="kisi"
          label="Grupta kaç kişi bu soruda hemfikir?"
          rules={[{ required: true, message: "Lütfen kişi sayısını giriniz" }]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
        {onEdit && (
          <>
            <Form.Item name="cevap" label="Cevap">
              <Input.TextArea rows={6} placeholder="Cevap" />
            </Form.Item>
            <Form.Item name="cevapTarihi" label="Cevap Tarihi">
              <DatePicker
                showTime
                format="DD-MM-YYYY"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item name="cevaplayan" label="Cevaplayan">
              <Input
                prefix={
                  <UserOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
                placeholder="Cevaplayan"
              />
            </Form.Item>
            <Space size="large">
              <Form.Item
                name="isActive"
                label="Is Active?"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                name="isArchived"
                label="Is Archived?"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Space>
          </>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MutEnquireHome;
